import { StoryblokComponent } from 'gatsby-source-storyblok';
import React, { useState } from 'react'
import FaqMeta from '../FaqMeta';
import './pricing.scss';

const Pricing = ({ story }) => {
    const { content: Content } = story
    const { faq_block } = Content
    const [active_indexes, setIndexes] = useState([])
    return (
        <div className='pricing-section'>
            <div className='container'>
                <div className='pricing-inner-section'>
                    <div className='faqs_section'>
                        <h2> Frequently asked questions </h2>
                        <FaqMeta
                            questions={faq_block?.map(({question, answer}) => ({ question, answer }))}
                        />
                        {faq_block?.map((item, idx) => {
                            return (
                                <div className={active_indexes?.includes(idx) ? 'faq-cover active' : 'faq-cover'} >
                                    <StoryblokComponent blok={item} onChange={(e) => { active_indexes.includes(idx) ? setIndexes(active_indexes.filter(i => i !== idx)) : setIndexes([...active_indexes, idx]) }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Pricing