import React from 'react';
import * as styles from './CompareTable.module.scss';
import arrowIcon from '../../images/arrow-icon.svg';

/**
 * Компонент PricingComparisonTable отображает таблицу для сравнения планов на основе данных, переданных через пропс `comparePlan`.
 * @param {Object} props - Свойства компонента
 * @param {Object} props.comparePlan - Объект, содержащий данные таблицы (thead и tbody)
 */
const PricingComparisonTable = ({ comparePlan }) => {
    // Деструктуризация: извлекаем заголовки и тело таблицы из объекта comparePlan.
    const { thead, tbody } = comparePlan;

    return (
        <div className={styles.container}>
            <h2>Compare plans & features</h2>

            <table>
                <thead>
                <tr>
                    {/* Итерируемся по массиву заголовков (thead) и рендерим каждый заголовок как ячейку <th> */}
                    {thead.map((header, index) => (
                        <th key={index}>{header.value}</th> // Используем index как ключ. Это допустимо, если порядок элементов не меняется.
                    ))}
                </tr>
                </thead>
                <tbody>
                {/* Итерируемся по массиву строк (tbody) и рендерим каждую строку */}
                {tbody.map((row, rowIndex) => {
                    // Проверяем, если все значения в строке (кроме первого) пустые
                    const isSubSectionHeader = row.body.slice(1).every(cell => !cell.value.trim());

                    return (
                        <tr key={rowIndex} className={isSubSectionHeader ? styles.subSectionHeader : ''}>
                            {/* Для каждой строки итерируемся по ячейкам и рендерим каждую ячейку как <td> */}
                            {row.body.map((cell, cellIndex) => (
                                <td key={cellIndex} className={styles.iconCell}>
                                    {/* Проверяем значение ячейки: если "true", рендерим иконку стрелки, иначе отображаем текстовое значение */}
                                    {cell.value === "true" ? (
                                        <img src={arrowIcon} alt="Check" className={styles.icon} />
                                    ) : cell.value}
                                </td>
                            ))}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

// Экспортируем компонент для использования в других частях приложения.
export default PricingComparisonTable;