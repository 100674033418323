import React from 'react';
import './pricing-plans-v2.scss';
import arrowIcon from '../../images/arrow-icon.svg';
import { render } from 'storyblok-rich-text-react-renderer';

const Index = ({ story }) => {
    const { content: Content } = story;
    const { title, description } = Content;

    const handleContactSalesClick = (e) => {
        e.preventDefault();
        if (window.Intercom) {
            window.Intercom('show');
        } else {
            console.error("Intercom is not initialized.");
        }
    };

    const trackPlanSelection = (planName) => {
        if (window.renta && typeof window.renta.track === 'function') {
            window.renta.track('Call to action', {
                plan: planName,
                call_to_action_type: 'Click on button'
            });
        } else {
            console.error("Renta is not initialized.");
        }
    };

    return (
        <section className="pricing-name">
            <header className="pricing-header">
                <h1 className="pricing-header-title">
                    <div className="h1">{title}</div>
                </h1>
                <div className="pricing-header-sub-title">
                    <p className="we-connect-to-your-d">{render(description)}</p>
                </div>
            </header>
            <div className="all-plan-card-block">
                <div className="plans">
                    <div className="all-plan-cards">
                        <article className="plan-card">
                            <h2 className="price-card-name">Starter</h2>
                            <p className="price-card-description">
                                Access essential data tools for small and hobby projects.
                            </p>
                            <div className="price">
                                <div className="price-sub-title">Free forever</div>
                                <div className="price-amount">$0</div>
                            </div>
                            <div className="price-button">
                                <a
                                    href="https://app.renta.im"
                                    className="price-button-text"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        trackPlanSelection('Starter');
                                    }}
                                >
                                    Start for free
                                </a>
                            </div>
                            <div className="plan-includes">Plan includes:</div>
                            <ul className="main-plan-features">
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon"/>
                                    <div className="plan-features-item">1 workspace</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">1 user</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">2 active syncs</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Up to 200K rows/month</div>
                                </li>
                            </ul>
                        </article>
                        <article className="plan-card">
                            <h2 className="price-card-name">Team</h2>
                            <p className="price-card-description">
                                Unlock all features with flexible, pay-as-you-go pricing.
                            </p>
                            <div className="price">
                                <div className="price-sub-title">Monthly, starts at</div>
                                <div className="price-amount">$30</div>
                            </div>
                            <div className="price-button">
                                <a
                                    href="https://app.renta.im"
                                    className="price-button-text"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        trackPlanSelection('Team');
                                    }}
                                >
                                    Start for free
                                </a>
                            </div>
                            <div className="plan-includes">Plan includes:</div>
                            <ul className="main-plan-features">
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon"/>
                                    <div className="plan-features-item">Unlimited workspaces</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon"/>
                                    <div className="plan-features-item">Unlimited users</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Unlimited active syncs</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Up to 500M rows/month</div>
                                </li>
                            </ul>
                        </article>
                        <article className="plan-card">
                            <h2 className="price-card-name">Unlimited</h2>
                            <p className="price-card-description">
                                Ideal for marketing agencies and data analytics consultants.
                            </p>
                            <div className="price">
                                <div className="price-sub-title">Monthly, fixed price</div>
                                <div className="price-amount">$500</div>
                            </div>
                            <div className="price-button">
                                <a
                                    href="https://app.renta.im"
                                    className="price-button-text"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        trackPlanSelection('Unlimited');
                                    }}
                                >
                                    Start for free
                                </a>
                            </div>
                            <div className="plan-includes">Plan includes:</div>
                            <ul className="main-plan-features">
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon"/>
                                    <div className="plan-features-item">1 workspaces</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon"/>
                                    <div className="plan-features-item">10 users</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">200 active syncs</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Unlimited rows/month</div>
                                </li>
                            </ul>
                        </article>
                        <article className="plan-card">
                            <h2 className="price-card-name">Enterprise</h2>
                            <p className="price-card-description">
                                Tailored solutions designed to fit your team’s unique needs.
                            </p>
                            <div className="price">
                                <div className="price-sub-title">Annually, starts at</div>
                                <div className="price-amount">$20,000</div>
                            </div>
                            <div className="price-button">
                                <a href="#" className="price-button-text" onClick={(e) => {
                                    trackPlanSelection('Enterprise');
                                    handleContactSalesClick(e);
                                }}>
                                    Contact sales
                                </a>
                            </div>
                            <div className="plan-includes">
                                Everything in Team, plus:
                                <br/>
                            </div>
                            <ul className="main-plan-features">
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Single sign-on</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Custom SLAs</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Dedicated expert support</div>
                                </li>
                                <li className="plan-features-block">
                                    <img className="arrow-icon" src={arrowIcon} alt="icon" />
                                    <div className="plan-features-item">Unlimited rows/month</div>
                                </li>
                            </ul>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Index;
